var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.service
    ? _c("Grid", {
        attrs: {
          hide_actions: false,
          service: _vm.service,
          route_name: "missives",
          column_formats: {
            created_at: (val) => {
              return _vm.$utils.format.date(val)
            },
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }